<template>
  <!-- 课程介绍页 -->
  <div class="introduce">
    <div class="introduce_center" v-if="introData !== null">
      <header>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/course' }" v-if="showone == 0">课程案例</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: `${showp}` }" v-else>{{ shown }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ introData.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </header>
      <nav>
        <div class="h_left">
          <img :src="introData.pictureUrl" alt />
          <!-- <img src="../../../assets/components/Group 107.png" alt /> -->
          <div class="h_icon" v-if="showone == 0 || showone == 1">
            <p class="h_p_like">
              <i class="like" v-if="!praiseFlag" @click="messagePraise"></i>
              <i class="likes" v-else @click="deletePraise"></i>
              <span>{{ introData.praiseCount }}</span>
            </p>
            <p class="h_p_star">
              <i class="star" v-if="!collectionFlag" @click="messageCollection"></i>
              <i class="stars" v-else @click="messageDeleteCollection"></i>
              <span>{{ introData.collectionCount }}</span>
            </p>
            <p class="h_p_play">
              <i></i>
              <span>{{ introData.playCount }}</span>
            </p>
            <p class="h_p_comment">
              <i></i>
              <span>{{ introData.commentCount }}</span>
            </p>
          </div>
        </div>
        <div class="h_right">
          <p class="r_title">{{ introData.title }}</p>
          <p class="r_user">上传者：{{ introData.createName }}</p>
          <p class="r_subject">学科：{{ introData.subjectName }}</p>
          <!-- <p class="r_time">创建时间：{{introData.createTime}}</p> -->
          <p class="r_period">学段：{{ introData.periodName }}</p>
          <p class="r_grade">年级：{{ introData.gradeName }}</p>
          <el-button type="primary" @click="startStudy(null, null, null)" v-if="showone == 0 || showone == 1">开始学习</el-button>
          <el-button type="primary" @click="startStudy(null, null, null)" v-else>查看视频</el-button>
          <!-- <el-button type="primary" @click="enterCircle">申请加入教研圈</el-button> -->
        </div>
      </nav>
      <div class="intro_down">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="课程简介" name="first">
            <p class="d_view">{{ introData.conent }}</p>
          </el-tab-pane>
          <el-tab-pane label="课程目录" name="second">
            <div class="classCiew">
              <el-collapse v-model="activeNames" @change="handleChange" accordion>
                <el-collapse-item v-for="(item, index) in directory" :key="item.id" :title="item.name">
                  <div
                    class="collapseList"
                    v-for="(items, indexs) in item.list"
                    :key="items.id"
                    @click="startStudy(items.id, items.name, `${index + '-' + indexs}`)"
                    style="cursor: pointer"
                  >
                    <div class="c_left">
                      <div>
                        <i class="el-icon-video-play"></i>
                        {{ items.name }}
                      </div>
                      <div class="l_second">{{ items.resourceTime }}</div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-tab-pane>
          <el-tab-pane label="课程资料" name="third">
            <div class="t_third" v-for="item in resources" :key="item.resouceId">
              <div class="th_left">
                <!-- <span>{{item.resouceId}}</span> -->
                <!-- <span class="th_second">{{item.resouceName}}</span> -->
                <!-- {{item}} -->
                <p class="th_second" @click="downLoad(item)">
                  <span>{{ item.resourceName }}</span>
                  <i class="el-icon-download" style="margin-left: 5px"></i>
                </p>
                <p class="th_five">所属课时：</p>
                <p class="tj_four" v-for="(items, index) of item.resouceCatelog" :key="index">{{ items }}</p>
                <!-- </p> -->
              </div>
              <!-- <el-button type="primary" size="small" @click="downLoad(item.resouceUrl)" style="height:40px">下载</el-button> -->
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 默认tabs
      activeName: "second", //tabs切换
      activeNames: ["1"], //collapse切换
      //
      introData: null, // 课程数据
      directory: null, //课程目录
      resources: null, //课程资料
      // 点赞
      collectionFlag: null, //收藏
      praiseFlag: null, //点赞
      // 分页
      total: null,
      //个人中心和课程案列判断
      showone: null,
      showp: null,
      shown: null,
    };
  },
  created() {
    console.log(this.$route.query.id, "id1112222", this.$route.query.cardsite);
    this.showone = this.$route.query.cardsite;
    console.log(window.sessionStorage.getItem("defaultindex"), window.sessionStorage.getItem("defaultname"));
    this.showp = window.sessionStorage.getItem("defaultindex");
    this.shown = window.sessionStorage.getItem("defaultname");

    this.queryCourselist(this.$route.query.id); //课程信息
    this.getClasslogue(this.$route.query.id); //目录
    // this.getClasslogue2(this.$route.query.id); //资料
    // 点赞和收藏
    this.queryMessageStatus(this.$route.query.id);
    //资源资料
    this.queryResourceDetail(this.$route.query.id);
  },
  methods: {
    // 获取课程信息
    async queryCourselist(id) {
      let form = new FormData();
      form.append("courseId", id);
      this.$Http.queryCourseDetailByID(form).then((res) => {
        console.log(res, "获取信息");
        if (res.status == 200) {
          let aData = JSON.parse(JSON.stringify(res.data));
          aData.periodName = aData.periodName.join("/");
          aData.gradeName = aData.gradeName.join("/");
          aData.subjectName = aData.subjectName.join("/");
          this.introData = aData;
        }
      });
      return;
      let datas = JSON.stringify({
        courseIdList: [id], //课程id集合
        courseType: 1, //课程类型(1-课程，2-资料)
        sortId: 1, //排序ID(1-上传时间，2-播放量，3-评价数量，4-收藏数量，5-点赞数量，6-下载量)
        sortType: 1, //排序类型（1-正序，2-倒序）
      });
      const { data, status } = await this.$Http.queryCourselist(datas);
      if (status === 200) {
        // console.log(data, status, "获取课程", data.list[0]);
        // let aData = data.list[0];
        let aData = JSON.parse(JSON.stringify(data.list[0]));
        aData.periodName = aData.periodName.join("/");
        aData.gradeName = aData.gradeName.join("/");
        aData.subjectName = aData.subjectName.join("/");
        this.introData = aData;
        // window.sessionStorage.setItem("classInfo", JSON.stringify(data.list));
      }
    },

    //获取课程目录和资源资料
    async getClasslogue(id) {
      const { data, status } = await this.$Http.queryCatalogue({
        courseId: id, //课程id
        type: 1, //类型 1-章节 2-课时 3-课时下的资源 4-课程下的资源
      });
      console.log(data, status, "获取课程目录和资源资料1");
      if (status == 200) {
        let aRes = data.catalogueList;
        for (let x = 0; x < aRes.length; x++) {
          // this.getClasslogue3(this.$route.query.id, aRes[x].id);
          const res = await this.$Http.queryCatalogue({
            catalogueId: aRes[x].id,
            courseId: id, //课程id
            type: 2, //类型 1-章节 2-课时 3-课时下的资源 4-课程下的资源
          });
          // console.log(res.data, "获取课程目录和资源资料2");
          if (status == 200) {
            aRes[x].list = res.data.catalogueList;
            // let bRes = res.data.catalogueList;
            // for (let y = 0; y < bRes.length; y++) {
            //   this.getClasslogue3(this.$route.query.id, bRes[y].id);
            // }
          }
        }
        this.directory = aRes;
      }
    },

    // 获取资源
    async queryResourceDetail(id) {
      console.log(id, "获取资源");
      let formdata = new FormData();
      formdata.append("courseId", id - 0);
      const { data, status } = await this.$Http.queryResourceDetail(formdata);
      if (status == 200) {
        console.log(data, status, "获取课程资源");
        this.resources = data;
      }
    },
    //获取资源
    // async getClasslogue2(id) {
    //   const { data, status } = await this.$Http.queryCatalogue({
    //     courseId: id, //课程id
    //     type: 4, //类型 1-章节 2-课时 3-课时下的资源 4-课程下的资源
    //   });
    //   console.log(data, status, "获取课程资源资料4");
    //   if (status == 200) {
    //     this.resources = data.resource.videoList;
    //   }
    // },

    //获取资料
    // async getClasslogue3(id, ids) {
    //   const { data, status } = await this.$Http.queryCatalogue({
    //     catalogueId: ids,
    //     courseId: id, //课程id
    //     type: 3, //类型 1-章节 2-课时 3-课时下的资源 4-课程下的资源
    //   });
    //   console.log(data, status, "获取课程资源资料3");
    //   if (status == 200) {
    //     // this.resources = data.resource.videoList;
    //     console.log(data.resource, "data.resource", data.resource.docementList);
    //     let aa = JSON.stringify(data.resource) === "{}";
    //     console.log("对象是否为空：", aa);
    //     if (!aa) {
    //       // console.log(data.resource.docementList !== "undefined", "undefined");
    //       this.resources.push(...data.resource.docementList);
    //     }
    //   }
    // },

    // 点赞
    async messagePraise() {
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.messagePraise({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        // console.log(data, status, "点赞");
        this.queryMessageStatus(id);
        this.queryCourselist(id);
      }
    },
    // 取消点赞
    async deletePraise() {
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.deletePraise({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        // console.log(data, status, "取消点赞");
        this.queryMessageStatus(id);
        this.queryCourselist(id);
      }
    },
    // 收藏
    async messageCollection() {
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.messageCollection({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        // console.log(data, status, "收藏");
        this.queryMessageStatus(id);
        this.queryCourselist(id);
      }
    },
    // 取消收藏
    async messageDeleteCollection() {
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.messageDeleteCollection({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        // console.log(data, status, "取消收藏");
        this.queryMessageStatus(id);
        this.queryCourselist(id);
      }
    },
    // 查询点赞和收藏
    async queryMessageStatus(id) {
      const { data, status } = await this.$Http.queryMessageStatus({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        console.log(data, status, "点赞和收藏");
        this.collectionFlag = data.collectionFlag;
        this.praiseFlag = data.praiseFlag;
      }
    },

    // --
    // 开始学习
    async startStudy(id, name, index) {
      // console.log(this.directory[0].list[0].id, "directory", id);
      let ids = this.$route.query.id - 0;
      let rid = null;
      let rname = null;
      let rindex = null;
      if (id == null) {
        console.log(this.directory[0].list[0], "directory", id);
        rid = this.directory[0].list[0].id;
      } else {
        rid = id;
      }
      if (name == null) {
        rname = this.directory[0].list[0].name;
      } else {
        rname = name;
      }

      if (index == null) {
        rindex = "0-0";
      } else {
        rindex = index;
      }

      if (this.showone == 2) {
        this.$router.push({
          name: "details",
          query: {
            nums: rid,
            id: this.$route.query.id,
            names: rname,
            index: rindex,
            cardsite: this.$route.query.cardsite,
          },
        });
        return;
      }
      const { data, status } = await this.$Http.startStudy({
        courseId: ids,
        resourceId: rid,
      });
      if (status == 200) {
        console.log(data, status, "开始学习");
        this.$router.push({
          name: "details",
          query: {
            nums: rid,
            id: this.$route.query.id,
            names: rname,
            index: rindex,
            cardsite: this.$route.query.cardsite,
          },
        });
      }
    },

    // ---
    // 下载
    async downLoad(item) {
      console.log("下载", item);
      let pardel = { url: item.resouceUrl };
      this.$axios({
        method: "get",
        url: "./api/file/downLoad",
        params: pardel,
        responseType: "blob",
      }).then(async (res) => {
        console.log(res, "下载文件");
        if (!res) {
          this.$message.error("文件获取失败");
          return;
        } else {
          await this.$Http
            .collect({
              resourceId: item.resourceId, //下载资源的id
              serviceId: this.$route.query.id, //业务id
              type: 1, //业务类型 1-课程/资料 2-教研圈
            })
            .then((result) => {
              console.log(result, "下载统计");
            });
        }
        if (res.status == 200) {
          console.log("开始下载");
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          let fileNames = item.resourceName;
          link.setAttribute("download", fileNames);
          document.body.appendChild(link);
          link.click();
        }
      });
    },

    // tabs的点击
    handleClick(tab, event) {
      console.log(tab, event, "click");
    },

    //折叠面板
    handleChange(val) {
      console.log(val, "change");
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.introduce {
  .introduce_center {
    width: 1200px;
    margin: 0 auto;
    header {
      padding: 15px 0;
    }
    nav {
      // width: 1200px;
      // height: 365px;
      padding: 20px;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      .h_left {
        img {
          width: 500px;
          height: 300px;
        }
        .h_icon {
          display: flex;
          justify-content: flex-start;
          .h_p_like {
            .like {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/blanklike.png") no-repeat;
            }
            .likes {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/solidlike.png") no-repeat;
            }
            span {
              display: inline-block;
              //   width: 29px;
              //   height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              //   line-height: 19px;
              vertical-align: super;
            }
          }
          .h_p_star {
            margin-left: 40px;

            .star {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/blankstar.png") no-repeat;
            }
            .stars {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/solidestar.png") no-repeat;
            }
            span {
              display: inline-block;
              //   width: 29px;
              //   height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              //   line-height: 19px;
              vertical-align: super;
            }
          }
          .h_p_play {
            margin-left: 40px;
            i {
              display: inline-block;
              width: 32px;
              height: 27px;
              background: url("../../../assets/icon/playbig.png") no-repeat;
            }
            span {
              display: inline-block;
              //   width: 29px;
              //   height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              //   line-height: 19px;
              vertical-align: super;
            }
          }
          .h_p_comment {
            margin-left: 40px;
            i {
              display: inline-block;
              width: 32px;
              height: 27px;
              background: url("../../../assets/icon/commentbig.png") no-repeat;
            }
            span {
              display: inline-block;
              //   width: 29px;
              //   height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              //   line-height: 19px;
              vertical-align: super;
            }
          }
        }
      }
      .h_right {
        width: 720px;
        height: 280px;
        padding: 10px 20px;
        // display: flex;
        // justify-content: flex-start;
        // flex-direction: column;
        .r_title {
          max-width: 720px;
          //   height: 29px;
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          //   line-height: 26px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 40px;
        }

        p {
          // width: 80px;
          // height: 21px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5d6066;
          // line-height: 19px;
          margin-bottom: 20px;
        }
        .el-button {
          width: 160px;
        }
      }
    }
    .intro_up {
      padding: 20px;
      .up_one {
        display: flex;
        justify-content: space-between;
        .up_one_left {
          width: 300px;
          height: 240px;
          img {
            width: 300px;
            height: 240px;
          }
        }
        .up_one_right {
          width: 890px;
          p {
            // height: 25px;
            line-height: 25px;
            font-size: 20px;
            font-weight: 600;
            padding: 0 15px;
            margin-bottom: 10px;
          }
          .el-button {
            margin-left: 15px;
          }
        }
      }
      .up_two {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
        .t_cord,
        .t_star,
        .t_play {
          margin-right: 20px;
        }
        .t_cord {
          cursor: pointer;
          .cord_one,
          .cord_two {
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: middle;
          }
          .cord_one {
            background: url("../../../assets/icon/index/core.png") no-repeat;
          }
          .cord_two {
            background: url("../../../assets/icon/index/cores.png") no-repeat;
          }
          span {
            // width: 22px;
            height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #8d9199;
            line-height: 14px;
          }
        }
        .t_star {
          cursor: pointer;
          i {
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: middle;
          }
          .cord_one {
            background: url("../../../assets/icon/index/star.png") no-repeat;
          }
          .cord_two {
            background: url("../../../assets/icon/index/stars.png") no-repeat;
          }
          span {
            // width: 22px;
            height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #8d9199;
            line-height: 14px;
          }
        }
        .t_play {
          i {
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: middle;
            background: url("../../../assets/icon/index/play.png") no-repeat;
          }
          span {
            // width: 22px;
            height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #8d9199;
            line-height: 14px;
          }
        }
        .t_notice {
          i {
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: middle;
            background: url("../../../assets/icon/index/play.png") no-repeat;
          }
          span {
            // width: 22px;
            height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #8d9199;
            line-height: 14px;
            margin-left: 2px;
          }
        }
      }
    }
    .intro_down {
      margin-top: 20px;
      padding: 0px 20px 20px;
      background-color: #fff;
      .el-tabs {
        // background-color: red;
        ::v-deep .el-tabs__item {
          font-size: 16px;
          height: 60px;
          line-height: 60px;
          font-weight: 500;
        }
        .d_view {
          padding: 10px 20px;
        }
        .classCiew {
          ::v-deep .el-collapse-item__header {
            font-size: 16px;
            padding-left: 10px;
            border-bottom: 1px solid #d7dce4;
            // width: 160px;
            // height: 21px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #141516;
            // line-height: 19px;
          }
          ::v-deep .el-collapse-item__content {
            padding: 0;
          }
          ::v-deep .el-collapse {
            border-top: 0;
          }
          .collapseList {
            // width: 1090px;
            // height: 0px;
            // opacity: 1;
            // border-bottom: 1px solid #d7dce4;

            // display: flex;
            // justify-content: space-between;
            // margin-top: 10px;
            padding: 10px 0;
            margin: 0px 20px;
            font-size: 14px;
            font-weight: 500;
            .c_left {
              display: flex;
              justify-content: space-between;
              margin-left: 10px;
              // width: 202px;
              // height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #5d6066;
              // line-height: 19px;
              .l_second {
                margin-left: 20px;
              }
            }
          }
        }
        .t_third {
          background-color: #fff;
          // display: flex;
          // justify-content: space-between;
          padding: 10px 5px 5px;
          // border-bottom: 1px solid #ebeef5;
          .th_left {
            .th_second {
              cursor: pointer;
              // margin-left: 10px;
              // width: 128px;
              // height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 600;
              color: #141516;
              // line-height: 19px;
            }
            .th_second:hover {
              color: blue;
            }
            .th_five {
              margin-top: 15px;
              // width: 70px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #5d6066;
              // line-height: 16px;
            }
            .tj_four {
              margin-top: 10px;
              // width: 1052px;
              // height: 34px;
              background: rgba(25, 119, 249, 0.06);
              // border-radius: 4px 4px 4px 4px;
              // opacity: 1;
              // width: 207px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #5d6066;
              // line-height: 16px;
            }
          }
        }
        .s_text {
          width: 1140px;
          height: 110px;
          padding: 20px 10px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #000;
          .text_left {
            width: 150px;
            height: 110px;
            .userPhoto {
              width: 80px;
              height: 80px;
              margin: 0 auto;
              border-radius: 50%;
              background: #fff;
            }
            .user_name {
              margin-top: 10px;
              text-align: center;
            }
          }
          .text_line {
            width: 1px;
            height: 60px;
            margin-top: 10px;
            background-color: #000;
          }
          .text_right {
            width: 1039px;
            height: 110px;
            padding: 0 15px;
            .r_content {
              height: 90px;
              p {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
              }
            }
            .r_time {
              height: 10px;
              display: flex;
              justify-content: flex-end;
            }
          }
        }
        .block {
          padding: 20px 10px 0;
          display: flex;
          justify-content: end;
        }
      }
    }
  }
}
</style>
